import AuthService from '@/services/auth.service'
import User from '../../models/user'
import AccountService from '@/services/account.service'

const authService = new AuthService()

const user = new User(JSON.parse(localStorage.getItem('user')))
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null }

export const auth = {
  namespaced: true,
  state: initialState,
  mutations: {
    logintwoWayCheck (state) {
      state.status.loggedIn = false
      state.user = null
    },
    loginSuccess (state, user) {
      state.status.loggedIn = true
      state.user = user
    },
    loginFailure (state) {
      state.status.loggedIn = false
      state.user = null
    },
    logout (state) {
      state.status.loggedIn = false
      state.user = null
    },
    registerSuccess (state) {
      state.status.loggedIn = false
    },
    registerFailure (state) {
      state.status.loggedIn = false
    },
    updateUserCountry (state, country) {
      if (state.user) {
        state.user.isoCountry = country.iso_code_2
      }
      const user = JSON.parse(localStorage.getItem('user'))
      if (user) {
        localStorage.setItem('user',
          JSON.stringify({
            ...user,
            isoCountry: country.iso_code_2,
          }),
        )
      }
    },
    updateUserLanguage (state, language) {
      if (state.user) {
        state.user.lang = language
      }
      const user = JSON.parse(localStorage.getItem('user'))
      if (user) {
        localStorage.setItem('user',
          JSON.stringify({
            ...user,
            lang: language,
          }),
        )
      }
    },
  },
  actions: {
    autologin ({ commit }, autologinData) {
      return authService.autologin(autologinData).then((user) => {
        commit('loginSuccess', user)
        return Promise.resolve(user)
      })
    },
    login ({ commit }, user) {
      return authService.login(user).then(
        user => {
          commit('logintwoWayCheck', user)
          return Promise.resolve(user)
        },
        error => {
          commit('loginFailure')
          return Promise.reject(error)
        },
      )
    },
    twoWayCheck ({ commit }, user) {
      return authService.twoWayCheck(user).then(
        user => {
          commit('loginSuccess', user)
          return Promise.resolve(user)
        },
        error => {
          commit('loginFailure')
          return Promise.reject(error)
        },
      )
    },
    logout ({ commit }) {
      return authService.logout().then(
        () => {
          commit('logout')
          return Promise.resolve()
        },
      )
    },
    register ({ commit }, user) {
      return authService.register(user).then(
        response => {
          commit('registerSuccess')
          return Promise.resolve(response.data)
        },
        error => {
          commit('registerFailure')
          return Promise.reject(error)
        },
      )
    },
    setUserCountry ({ commit }, user) {
      AccountService.getUserCountry()
      .then(response => {
        commit('updateUserCountry', response.country)
        return Promise.resolve(response)
      })
    },
    setUserLanguage ({ commit }, language) {
      return commit('updateUserLanguage', language)
    },
  },
  getters: {
    getUser (state) {
      if (!state.user) {
        state.user = new User(JSON.parse(localStorage.getItem('user')))
      }

      return { ...state.user }
    },
    getUsername (state) {
      return state.user.username
    },
  },
}

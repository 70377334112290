export default class VideoPlayer {
  es= [
    {
      id: 1,
      url: 'https://player.vimeo.com/video/683684527?h=eb91dca8b4',
      img: require('@/assets/img/video-tutorials/v1-cmp-sms-basica.jpg'),
      title: 'Campaña SMS básica',
    },
    {
      id: 2,
      url: 'https://player.vimeo.com/video/684223648?h=83e33816ff',
      img: require('@/assets/img/video-tutorials/v2-cmp-sms-recordatorio.jpg'),
      title: 'Campaña de SMS recordatorio',
    },
    {
      id: 3,
      url: 'https://player.vimeo.com/video/684223616?h=0315f90fa9',
      img: require('@/assets/img/video-tutorials/v3-cmp-sms-aniversario.jpg'),
      title: 'Campaña de SMS aniversario',
    },
    {
      id: 4,
      url: 'https://player.vimeo.com/video/684223780?h=965deb9bc2',
      img: require('@/assets/img/video-tutorials/v4-plantilla-mailing.jpg'),
      title: 'Landing page o plantilla de mailing',
    },
    {
      id: 5,
      url: 'https://player.vimeo.com/video/684223728?h=4096ffa483',
      img: require('@/assets/img/video-tutorials/v5-sms-landing-page.jpg'),
      title: 'Campaña de SMS landing page',
    },
    {
      id: 6,
      url: 'https://player.vimeo.com/video/684223682?h=5d4999d87a',
      img: require('@/assets/img/video-tutorials/v6-cmp-voz-interactiva.jpg'),
      title: 'Campaña de VOZ interactiva',
    },
    {
      id: 7,
      url: 'https://player.vimeo.com/video/684223479?h=991bffed92',
      img: require('@/assets/img/video-tutorials/v7-plantilla-voz-interactiva.jpg'),
      title: 'Plantilla de VOZ interactiva',
    },
    {
      id: 8,
      url: 'https://player.vimeo.com/video/684223821?h=617a608db5',
      img: require('@/assets/img/video-tutorials/v8-crear-enviar-formulario.jpg'),
      title: 'Crear formularios',
    },
    {
      id: 9,
      url: 'https://player.vimeo.com/video/684223505?h=1350d1adb1',
      img: require('@/assets/img/video-tutorials/v9-cmp-mailing-bas.jpg'),
      title: 'Campaña de MAIL básica',
    },
    {
      id: 10,
      url: 'https://player.vimeo.com/video/684223583?h=31384b6d26',
      img: require('@/assets/img/video-tutorials/v10-cmp-mail-recordatorio.jpg'),
      title: 'Campaña de MAIL recordatorio',
    },
    {
      id: 11,
      url: 'https://player.vimeo.com/video/684223536?h=00d990b71e',
      img: require('@/assets/img/video-tutorials/v11-cmp-mail-aniversario.jpg'),
      title: 'Campaña de MAIL aniversario',
    },
    {
      id: 12,
      url: 'https://player.vimeo.com/video/684567587?h=fa14ed22de',
      img: require('@/assets/img/video-tutorials/v12-primeros-pasos.jpg'),
      title: 'Primeros pasos',
    },
  ]

  en= [
    {
      id: 1,
      url: 'https://player.vimeo.com/video/688898756?h=75fb26544f',
      img: require('@/assets/img/video-tutorials/en/v9-cmp-sms-bas.jpg'),
      title: 'Create your first Basic SMS campaign',
    },
    {
      id: 2,
      url: 'https://player.vimeo.com/video/688898274?h=742a02c905',
      img: require('@/assets/img/video-tutorials/en/v2-cmp-sms-recordatorio.jpg'),
      title: 'Create your first Reminder SMS campaign',
    },
    {
      id: 3,
      url: 'https://player.vimeo.com/video/688898351?h=8e980a11d0',
      img: require('@/assets/img/video-tutorials/en/v3-cmp-sms-aniversario.jpg'),
      title: 'Create your first Anniversary SMS campaign',
    },
    {
      id: 4,
      url: 'https://player.vimeo.com/video/688898386?h=d7af71d213',
      img: require('@/assets/img/video-tutorials/en/v4-plantilla-mailing.jpg'),
      title: 'Create a landing page or Mailing template',
    },
    {
      id: 5,
      url: 'https://player.vimeo.com/video/688898432?h=973eadb4fd',
      img: require('@/assets/img/video-tutorials/en/v5-sms-landing-page.jpg'),
      title: 'Create an SMS landing page campaign',
    },
    {
      id: 6,
      url: 'https://player.vimeo.com/video/688898470?h=0db5a28b88',
      img: require('@/assets/img/video-tutorials/en/v6-cmp-voz-interactiva.jpg'),
      title: 'Create your first Interactive Voice campaign',
    },
    {
      id: 7,
      url: 'https://player.vimeo.com/video/688898518?h=d2b898305a',
      img: require('@/assets/img/video-tutorials/en/v7-plantilla-voz-interactiva.jpg'),
      title: 'How to create an Interactive voice template',
    },
    {
      id: 8,
      url: 'https://player.vimeo.com/video/688898576?h=ad52221ea7',
      img: require('@/assets/img/video-tutorials/en/v8-crear-enviar-formulario.jpg'),
      title: 'How to create a form and send it via SMS or Email',
    },
    {
      id: 9,
      url: 'https://player.vimeo.com/video/688898578?h=a5edb9b5d5',
      img: require('@/assets/img/video-tutorials/en/v9-cmp-mailing-bas.jpg'),
      title: 'Create your first Basic Mailing campaign',
    },
    {
      id: 10,
      url: 'https://player.vimeo.com/video/688898616?h=4e53fda0d2',
      img: require('@/assets/img/video-tutorials/en/v10-cmp-mail-recordatorio.jpg'),
      title: 'Create your first Reminder email campaign',
    },
    {
      id: 11,
      url: 'https://player.vimeo.com/video/688898651?h=d4bfd424bc',
      img: require('@/assets/img/video-tutorials/en/v11-cmp-mail-aniversario.jpg'),
      title: 'Create your first Anniversary email campaign',
    },
    {
      id: 12,
      url: 'https://player.vimeo.com/video/688898690?h=76536a4705',
      img: require('@/assets/img/video-tutorials/en/v12-primeros-pasos.jpg'),
      title: 'First steps in {whiteLabelName}',
    },
  ]

  it= [
    {
      id: 1,
      url: 'https://player.vimeo.com/video/701608928?h=eff3aa7c35',
      img: require('@/assets/img/video-tutorials/it/v1-cmp-sms-basica.jpg'),
      title: 'Campagna Basica di SMS',
    },
    {
      id: 2,
      url: 'https://player.vimeo.com/video/701608947?h=0fa905b81a',
      img: require('@/assets/img/video-tutorials/it/v2-cmp-sms-recordatorio.jpg'),
      title: 'Campagna SMS di promemoria',
    },
    {
      id: 3,
      url: 'https://player.vimeo.com/video/701608967?h=6ba6f700b9',
      img: require('@/assets/img/video-tutorials/it/v3-cmp-sms-aniversario.jpg'),
      title: 'SMS di compleanno',
    },
    {
      id: 4,
      url: 'https://player.vimeo.com/video/701608989?h=9680ce6c4c',
      img: require('@/assets/img/video-tutorials/it/v4-plantilla-mailing.jpg'),
      title: 'Crea una Landing Page o Modello di mailing',
    },
    {
      id: 5,
      url: 'https://player.vimeo.com/video/701609014?h=2d89220f42',
      img: require('@/assets/img/video-tutorials/it/v5-sms-landing-page.jpg'),
      title: 'Crea un SMS Landing Page',
    },
    {
      id: 6,
      url: 'https://player.vimeo.com/video/701609045?h=d019ca2ba6',
      img: require('@/assets/img/video-tutorials/it/v6-cmp-voz-interactiva.jpg'),
      title: 'Chiamate interattive',
    },
    {
      id: 7,
      url: 'https://player.vimeo.com/video/701609078?h=30eee3d2ee',
      img: require('@/assets/img/video-tutorials/it/v7-plantilla-voz-interactiva.jpg'),
      title: 'Modello di chiamata interattiva',
    },
    {
      id: 8,
      url: 'https://player.vimeo.com/video/701609102?h=e39be8b105',
      img: require('@/assets/img/video-tutorials/it/v8-crear-enviar-formulario.jpg'),
      title: 'Creare un questionario ed inviarlo via SMS o Email',
    },
    {
      id: 9,
      url: 'https://player.vimeo.com/video/701609121?h=8698a22ada',
      img: require('@/assets/img/video-tutorials/it/v9-cmp-mailing-bas.jpg'),
      title: 'Campagna Basica di Mailing',
    },
    {
      id: 10,
      url: 'https://player.vimeo.com/video/701609148?h=a41fc2eeb3',
      img: require('@/assets/img/video-tutorials/it/v10-cmp-mail-recordatorio.jpg'),
      title: 'Email di Promemoria',
    },
    {
      id: 11,
      url: 'https://player.vimeo.com/video/701609173?h=71f965501a',
      img: require('@/assets/img/video-tutorials/it/v11-cmp-mail-aniversario.jpg'),
      title: 'Email di compleanno',
    },
    {
      id: 12,
      url: 'https://player.vimeo.com/video/701608905?h=8d22b71038',
      img: require('@/assets/img/video-tutorials/it/v12-primeros-pasos.jpg'),
      title: 'Primi passi nella tua piattaforma di marketing multicanale',
    },
  ]

  pt= [
    {
      id: 1,
      url: 'https://player.vimeo.com/video/688898756?h=75fb26544f',
      img: require('@/assets/img/video-tutorials/en/v9-cmp-sms-bas.jpg'),
      title: 'Create your first Basic SMS campaign',
    },
    {
      id: 2,
      url: 'https://player.vimeo.com/video/688898274?h=742a02c905',
      img: require('@/assets/img/video-tutorials/en/v2-cmp-sms-recordatorio.jpg'),
      title: 'Create your first Reminder SMS campaign',
    },
    {
      id: 3,
      url: 'https://player.vimeo.com/video/688898351?h=8e980a11d0',
      img: require('@/assets/img/video-tutorials/en/v3-cmp-sms-aniversario.jpg'),
      title: 'Create your first Anniversary SMS campaign',
    },
    {
      id: 4,
      url: 'https://player.vimeo.com/video/688898386?h=d7af71d213',
      img: require('@/assets/img/video-tutorials/en/v4-plantilla-mailing.jpg'),
      title: 'Create a landing page or mailing template',
    },
    {
      id: 5,
      url: 'https://player.vimeo.com/video/688898432?h=973eadb4fd',
      img: require('@/assets/img/video-tutorials/en/v5-sms-landing-page.jpg'),
      title: 'Create an SMS landing page campaign',
    },
    {
      id: 6,
      url: 'https://player.vimeo.com/video/688898470?h=0db5a28b88',
      img: require('@/assets/img/video-tutorials/en/v6-cmp-voz-interactiva.jpg'),
      title: 'Create your first Interactive Voice campaign',
    },
    {
      id: 7,
      url: 'https://player.vimeo.com/video/688898518?h=d2b898305a',
      img: require('@/assets/img/video-tutorials/en/v7-plantilla-voz-interactiva.jpg'),
      title: 'How to create an interactive voice template',
    },
    {
      id: 8,
      url: 'https://player.vimeo.com/video/688898576?h=ad52221ea7',
      img: require('@/assets/img/video-tutorials/en/v8-crear-enviar-formulario.jpg'),
      title: 'How to create a form and send it via SMS or Email',
    },
    {
      id: 9,
      url: 'https://player.vimeo.com/video/688898578?h=a5edb9b5d5',
      img: require('@/assets/img/video-tutorials/en/v9-cmp-mailing-bas.jpg'),
      title: 'Create your first basic mailing campaign',
    },
    {
      id: 10,
      url: 'https://player.vimeo.com/video/688898616?h=4e53fda0d2',
      img: require('@/assets/img/video-tutorials/en/v10-cmp-mail-recordatorio.jpg'),
      title: 'Create your first reminder email campaign',
    },
    {
      id: 11,
      url: 'https://player.vimeo.com/video/688898651?h=d4bfd424bc',
      img: require('@/assets/img/video-tutorials/en/v11-cmp-mail-aniversario.jpg'),
      title: 'Create your first anniversary email campaign',
    },
    {
      id: 12,
      url: 'https://player.vimeo.com/video/688898690?h=76536a4705',
      img: require('@/assets/img/video-tutorials/en/v12-primeros-pasos.jpg'),
      title: 'First steps in {whiteLabelName}',
    },
  ]

  ro= [
    {
      id: 1,
      url: 'https://player.vimeo.com/video/688898756?h=75fb26544f',
      img: require('@/assets/img/video-tutorials/en/v9-cmp-sms-bas.jpg'),
      title: 'Create your first Basic SMS campaign',
    },
    {
      id: 2,
      url: 'https://player.vimeo.com/video/688898274?h=742a02c905',
      img: require('@/assets/img/video-tutorials/en/v2-cmp-sms-recordatorio.jpg'),
      title: 'Create your first Reminder SMS campaign',
    },
    {
      id: 3,
      url: 'https://player.vimeo.com/video/688898351?h=8e980a11d0',
      img: require('@/assets/img/video-tutorials/en/v3-cmp-sms-aniversario.jpg'),
      title: 'Create your first Anniversary SMS campaign',
    },
    {
      id: 4,
      url: 'https://player.vimeo.com/video/688898386?h=d7af71d213',
      img: require('@/assets/img/video-tutorials/en/v4-plantilla-mailing.jpg'),
      title: 'Create a landing page or mailing template',
    },
    {
      id: 5,
      url: 'https://player.vimeo.com/video/688898432?h=973eadb4fd',
      img: require('@/assets/img/video-tutorials/en/v5-sms-landing-page.jpg'),
      title: 'Create an SMS landing page campaign',
    },
    {
      id: 6,
      url: 'https://player.vimeo.com/video/688898470?h=0db5a28b88',
      img: require('@/assets/img/video-tutorials/en/v6-cmp-voz-interactiva.jpg'),
      title: 'Create your first Interactive Voice campaign',
    },
    {
      id: 7,
      url: 'https://player.vimeo.com/video/688898518?h=d2b898305a',
      img: require('@/assets/img/video-tutorials/en/v7-plantilla-voz-interactiva.jpg'),
      title: 'How to create an interactive voice template',
    },
    {
      id: 8,
      url: 'https://player.vimeo.com/video/688898576?h=ad52221ea7',
      img: require('@/assets/img/video-tutorials/en/v8-crear-enviar-formulario.jpg'),
      title: 'How to create a form and send it via SMS or Email',
    },
    {
      id: 9,
      url: 'https://player.vimeo.com/video/688898578?h=a5edb9b5d5',
      img: require('@/assets/img/video-tutorials/en/v9-cmp-mailing-bas.jpg'),
      title: 'Create your first basic mailing campaign',
    },
    {
      id: 10,
      url: 'https://player.vimeo.com/video/688898616?h=4e53fda0d2',
      img: require('@/assets/img/video-tutorials/en/v10-cmp-mail-recordatorio.jpg'),
      title: 'Create your first reminder email campaign',
    },
    {
      id: 11,
      url: 'https://player.vimeo.com/video/688898651?h=d4bfd424bc',
      img: require('@/assets/img/video-tutorials/en/v11-cmp-mail-aniversario.jpg'),
      title: 'Create your first anniversary email campaign',
    },
    {
      id: 12,
      url: 'https://player.vimeo.com/video/688898690?h=76536a4705',
      img: require('@/assets/img/video-tutorials/en/v12-primeros-pasos.jpg'),
      title: 'First steps in {whiteLabelName}',
    },
  ]

  fr= [
    {
      id: 1,
      url: 'https://player.vimeo.com/video/688898756?h=75fb26544f',
      img: require('@/assets/img/video-tutorials/en/v9-cmp-sms-bas.jpg'),
      title: 'Create your first Basic SMS campaign',
    },
    {
      id: 2,
      url: 'https://player.vimeo.com/video/688898274?h=742a02c905',
      img: require('@/assets/img/video-tutorials/en/v2-cmp-sms-recordatorio.jpg'),
      title: 'Create your first Reminder SMS campaign',
    },
    {
      id: 3,
      url: 'https://player.vimeo.com/video/688898351?h=8e980a11d0',
      img: require('@/assets/img/video-tutorials/en/v3-cmp-sms-aniversario.jpg'),
      title: 'Create your first Anniversary SMS campaign',
    },
    {
      id: 4,
      url: 'https://player.vimeo.com/video/688898386?h=d7af71d213',
      img: require('@/assets/img/video-tutorials/en/v4-plantilla-mailing.jpg'),
      title: 'Create a landing page or mailing template',
    },
    {
      id: 5,
      url: 'https://player.vimeo.com/video/688898432?h=973eadb4fd',
      img: require('@/assets/img/video-tutorials/en/v5-sms-landing-page.jpg'),
      title: 'Create an SMS landing page campaign',
    },
    {
      id: 6,
      url: 'https://player.vimeo.com/video/688898470?h=0db5a28b88',
      img: require('@/assets/img/video-tutorials/en/v6-cmp-voz-interactiva.jpg'),
      title: 'Create your first Interactive Voice campaign',
    },
    {
      id: 7,
      url: 'https://player.vimeo.com/video/688898518?h=d2b898305a',
      img: require('@/assets/img/video-tutorials/en/v7-plantilla-voz-interactiva.jpg'),
      title: 'How to create an interactive voice template',
    },
    {
      id: 8,
      url: 'https://player.vimeo.com/video/688898576?h=ad52221ea7',
      img: require('@/assets/img/video-tutorials/en/v8-crear-enviar-formulario.jpg'),
      title: 'How to create a form and send it via SMS or Email',
    },
    {
      id: 9,
      url: 'https://player.vimeo.com/video/688898578?h=a5edb9b5d5',
      img: require('@/assets/img/video-tutorials/en/v9-cmp-mailing-bas.jpg'),
      title: 'Create your first basic mailing campaign',
    },
    {
      id: 10,
      url: 'https://player.vimeo.com/video/688898616?h=4e53fda0d2',
      img: require('@/assets/img/video-tutorials/en/v10-cmp-mail-recordatorio.jpg'),
      title: 'Create your first reminder email campaign',
    },
    {
      id: 11,
      url: 'https://player.vimeo.com/video/688898651?h=d4bfd424bc',
      img: require('@/assets/img/video-tutorials/en/v11-cmp-mail-aniversario.jpg'),
      title: 'Create your first anniversary email campaign',
    },
    {
      id: 12,
      url: 'https://player.vimeo.com/video/688898690?h=76536a4705',
      img: require('@/assets/img/video-tutorials/en/v12-primeros-pasos.jpg'),
      title: 'First steps in {whiteLabelName}',
    },
  ]
}
